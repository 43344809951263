.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: transparent !important;
  border: 0px !important;
  border-radius: 0px;
}

.right-overflow1 span.form-validation-span {
  display: none;
}

.right-overflow1 input:invalid[focused="true"] {
  border-bottom: 0px solid #fff;
}

.right-overflow1 input:invalid[focused="true"] ~ span.form-validation-span {
  display: block;
}

#select-input-info select {
  width: 100% !important;
  padding: 10px 0px 0px;
}

/* todo:phoneInput */
.right-overflow1 .react-tel-input {
  width: 100% !important;
  height: 60%;
  border: 0px solid var(--secondary-color);
  border-radius: 0px;
  color: var(--secondary-color) !important;
  font-weight: 400;
  font-size: 14px;
}

.right-overflow1 .react-tel-input .form-control {
  border-radius: 0px;
  border: none;
  width: 100%;
  height: 100%;
  background-color: var(--transit);
}

.right-overflow1 .PhoneInput input {
  background-color: var(--white);
  border: none;
}
/* !end */

/* todo: style for label tag */
.right-overflow1 label {
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-color);
}

/* todo:country-dropdown */
.right-overflow1 .country-select {
  width: 100%;
}
.flight-h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
  color: var(--mateBlack);
  padding: 20px 0px;
}

.adult-h4 h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  background: rgba(var(--secondary-rgb), 0.5);
  color: var(--secondary-color);
  text-align: left;
  padding: 8px 10px;
  border-radius: 0px;
  margin-bottom: 15px;
}

.adult-info select.user-info-select {
  width: 100% !important;
  padding: 7px 10px;
  border: none;
  border-radius: 0px;
  color: var(--secondary-color) !important;
  font-weight: 400;
  font-size: 14px;
  background-color: var(--transit);
  outline: none;
  /* border-bottom: 1px solid var(--secondary-color) !important; */
}

.adult-info input {
  width: 100% !important;
  padding: 7px 10px;
  border: none;
  border-radius: 0px;
  color: var(--secondary-color) !important;
  font-weight: 400;
  font-size: 14px;
  background-color: var(--transit);
  /* border-bottom: 1px solid var(--secondary-color) !important; */
  outline: none;
}

.adult-info input:focus {
  outline: none !important;
  border: 0px;
}

.adult-info .span-input {
  width: 100% !important;
  padding: 10px 10px;
  border: 1px solid var(--secondary-color);
  border-radius: 2px;
  color: var(--secondary-color) !important;
  /* font-family: "Poppins"; */
  font-weight: 400;
  font-size: 14px;
}
.user-info-calendar {
  position: absolute;
  top: 100%;
  left: 32px;
  border: 1px solid var(--secondary-color);
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  z-index: 10;
}
.user-info-calendar span {
  font-size: 14px;
  font-weight: 400;
}
.user-info-calendar span.rdrWeekDay {
  color: var(--primary-color);
}
.user-info-calendar .rdrMonthName {
  color: var(--secondary-color);
}
.adult-info input[type="date"] {
  position: relative;
}
.adult-info input[type="date"]::-webkit-calendar-picker-indicator {
  background-position: right;
  background-size: auto;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  bottom: 0;
  left: -10px;
  top: 0;
  width: 100%;
  height: 100%;
}

.adult-info ::-webkit-input-placeholder {
  color: var(--fontcolor) !important;
  font-weight: 400;
}

.checkBox {
  display: flex;
  align-items: center;
  padding: 20px 0px;
}

.checkBox input {
  color: var(--secondary-color) !important;
}

.checkBox label {
  color: var(--secondary-color);
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

#vehicle1 {
  accent-color: var(--secondary-color) !important;
  width: 20px !important;
  height: 20px !important;
}

.conatct-detail {
  padding: 40px 0px 0px;
}

.conatct-detail p {
  color: var(--secondary-color);
  font-style: normal;
  font-weight: 500;
  text-decoration: underline;
  font-size: 20px;
}

.booking-btn button {
  padding: 8px 40px;
  background: var(--secondary-color);
  border-radius: 2px;
  border: none;
  color: var(--white);
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
}
