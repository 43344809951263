.currencyBtn1 label {
  color: #003566 !important;
  font-size: 14px !important;
  line-height: 35px;
}

.currencyBtn1 input {
  width: 100%;
  border: 1px solid #003566;
  height: 29px;
}
