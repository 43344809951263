/*  all table style  */

.markup-table table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 5px;
  text-align: center;
}

.markup-table table tr th {
  background-color: var(--secondary-color);
  color: #fff;
  padding: 13px 5px;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
}

.markup-table table td {
  color: var(--black);
  font-size: 12px;
  padding: 10px;
}

.markup-table table tr:hover {
  background-color: #e0ecfb !important;
}

/* search */
.searchList2 input {
  width: 50%;
  border: none;
  border: 1px solid #70a5d8;
  border-radius: 2px;
  height: 34px;
  color: #000 !important;
  padding: 0px 10px !important;
}
.searchList2 input:focus {
  outline: none !important;
}

.searchList2 select {

  border: none;
  border: 1px solid #70a5d8;
  border-radius: 2px;
  height: 34px;
  color: #000 !important;
  padding: 0px 10px !important;
}
.searchList2 select:focus {
  outline: none !important;
}
