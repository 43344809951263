.trending-slider-item img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.trending-slider-item:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.trending-slider-item span {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: var(--white);
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}
.trending-slider-item:hover span {
  opacity: 1;
}
