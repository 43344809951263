.dashboard-live .normal-nav {
  background-color: var(--secondary-color);
  width: 90%;
}

.dashboard-live .sideBar-user .active-nav {
  background-color: var(--white);
  color: var(--white);
  text-decoration: none;
  padding: "0px 50px";
  width: 90%;
}
