.searchList1-select {
  width: 50%;
  border: none;
  border: 1px solid #70a5d8;
  border-radius: 2px;
  height: 34px;
  color: #000 !important;
  padding: 0px 5px !important;
  font-size: 14px;
  max-height: 300px;
  overflow-y: scroll;
  outline: none;
}
.searchList1-select option {
  width: 50%;
  border: none;
  border: 1px solid #70a5d8;
  border-radius: 2px;
  height: 34px;
  color: #003566 !important;
  padding: 10px 10px !important;
  margin: 10px !important;
  font-size: 14px;
  outline: none;
}

.searchList1 input:focus {
  outline: none !important;
}
