.airlineBtn1 input {
  width: 100%;
  border: 1px solid #003566;
  height: 29px;
}

.MuiPagination-root {
  button {
    color: red !important;
 }
}