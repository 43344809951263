/* ---------Tabs start--------------------- */

.dashboardTrack1 {
  margin-top: 0px;
}

.dashboardTrack1 .react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.parent-dashboardTrack1 {
  position: relative;
}

.dashboardTrack1 .gellery-tab {
  position: absolute;
  top: -0px;
  left: 30%;
  font-weight: 400;
}

.dashboardTrack1 .react-tabs__tab {
  display: inline-block;
  list-style: none;
  padding: 5px 8px;
  height: 20px;
  cursor: pointer;
  border-radius: 3px;
  /* border: 1px solid #70a5d8;
  color: #70a5d8; */
  color: #fff;
  display: flex;
  align-items: center;
  background-color: #2564b8;
}

.dashboardTrack1 .react-tabs__tab--selected {
  color: #fff !important;
  background-color: #ffa84d !important;
}

.dashboardTrack1 .react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.dashboardTrack1 .react-tabs__tab:focus {
  outline: none;
}

.dashboardTrack1 .react-tabs__tab-panel {
  display: none;
}

.dashboardTrack1 .react-tabs__tab-panel--selected {
  display: block;
}

/* tab style end */

/* table */
.dashboardTrack1 table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 5px;
  display: block;
  height: 195px;
  overflow-y: scroll;
  position: relative;
}

.dashboardTrack1 table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* .dashboardTrack1 table::-webkit-scrollbar {
  width: 5px;
  position: relative;
  top: 10px;
}

.dashboardTrack1 table::-webkit-scrollbar-thumb {
  background: #dc143c;
} */

th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

th:not([scope="row"]) {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 2;
}

.dashboardTrack1 table tr th {
  background-color: #ffa84d;
  color: #fff;
  padding: 8px 0px;
  font-weight: 300;
  font-size: 11px;
  width: 2%;
  position: sticky;
}
.dashboardTrack1 table td,
th {
  padding: 4px;
  text-align: center;
}
.dashboardTrack1 table td {
  color: #252733;
  font-size: 10.5px;
}
.dashboardTrack1 table tr:hover {
  background-color: #e0ecfb !important;
}

/* bottom part */

/* ---------Tabs start--------------------- */
.dashboardTrack2 {
  margin-top: 0px;
}
.dashboardTrack2 .react-tabs {
  -webkit-tap-highlight-color: transparent;
}
.parent-dashboardTrack2 {
  position: relative;
}
.dashboardTrack2 .gellery-tab {
  position: absolute;
  top: -0px;
  left: 30%;
  font-weight: 400;
}

.dashboardTrack2 .react-tabs__tab {
  display: inline-block;
  list-style: none;
  padding: 5px 8px;
  height: 20px;
  cursor: pointer;
  border-radius: 2px;
  /* border: 1px solid #70a5d8;
  color: #70a5d8; */
  color: #fff;
  display: flex;
  align-items: center;
  background-color: #2564b8;
}

.dashboardTrack2 .react-tabs__tab--selected {
  color: #fff !important;
  background-color: #ffa84d !important;
}

.dashboardTrack2 .react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.dashboardTrack2 .react-tabs__tab:focus {
  outline: none;
}

.dashboardTrack2 .react-tabs__tab-panel {
  display: none;
}

.dashboardTrack2 .react-tabs__tab-panel--selected {
  display: block;
}

/* tab style end */

/* table */
.dashboardTrack2 table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 5px;
  display: block;
  height: 200px;
  overflow-y: scroll;
  position: relative;
}

.dashboardTrack2 table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.dashboardTrack2 table tr th {
  background-color: #ffa84d;
  color: #fff;
  padding: 8px 0px;
  font-weight: 300;
  font-size: 11px;
  width: 2%;
  position: sticky;
}

.dashboardTrack2 table td,
th {
  padding: 4px;
  text-align: center;
}
.dashboardTrack2 table td {
  color: #252733;
  font-size: 10.5px;
}
.dashboardTrack2 table tr:hover {
  background-color: #e0ecfb !important;
}

@media (min-width: 1919px) and (max-width: 1920px) {
  .dashboardTrack1 table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 5px;
    display: block;
    height: 355px;
    overflow-y: scroll;
    position: relative;
  }

  .customMargin1 {
    margin-top: 20px !important;
  }
}
