.booking-link {
  color: #fff;
  border: 1px solid #2564b8;
  padding: 5px 10px 5px 10px;
  text-decoration: none;
  margin: 10px 10px 5px 0;
  transition: 0.3s ease;
  font-weight: normal;
  background-color: #2564b8;
  border-radius: 3px;
  display: "flex";
  align-items: center;
}

.active-booking-link {
  color: white;
  padding: 5px 10px 5px 10px;
  border: 1px solid #ffa84d;
  background-color: #ffa84d;
  text-decoration: none;
  font-weight: normal;
  margin: 10px 10px 5px 0;
}
