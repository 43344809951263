/* ---------Tabs start--------------------- */
.markUp {
  margin-top: 0px;
}
.markUp .react-tabs {
  -webkit-tap-highlight-color: transparent;
}
.parent-markUp {
  position: relative;
}
.markUp .gellery-tab {
  position: absolute;
  top: -0px;
  left: 30%;
  font-weight: 400;
}

.markUp .react-tabs__tab {
  display: inline-block;
  list-style: none;
  padding: 5px 20px;
  padding-bottom: 6px;
  height: 30px;
  cursor: pointer;
  border-radius: 2px;
  color: #fff;
  display: flex;
  align-items: center;
  background-color: var(--secondary-color);
}

.markUp .react-tabs__tab--selected {
  color: #fff !important;
  background-color: var(--primary-color) !important;
}

.markUp .react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.markUp .react-tabs__tab:focus {
  outline: none;
}

.markUp .react-tabs__tab-panel {
  display: none;
}

.markUp .react-tabs__tab-panel--selected {
  display: block;
}

/* tab style end */

.markupBox select {
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  font-size: 14px;
  background-color: transparent;
  padding-bottom: 10px;
  padding-top: 6px;
}

.markupBox input {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--secondary-color);
  padding-bottom: 9px;
  padding-top: 10px;
  outline: none;
}

.listBox li {
  padding: 8px 7px;
}
.listBox::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.listBox::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.listBox::-webkit-scrollbar-thumb {
  background: #555;
}
