.searchList1-select select {
  border: none;
  border: 1px solid #70a5d8;
  border-radius: 2px;
  height: 34px;
  color: #000 !important;
  padding: 0px 5px !important;
  font-size: 14px;
  max-height: 300px;
  overflow-y: scroll;
  width: 30%;
  color: #2564b8 !important;
  width: 100%;
}
.searchList1-select select option {
  /* width: 50%; */
  border: none;
  border: 1px solid #70a5d8;
  border-radius: 2px;
  height: 34px;
  color: #003566 !important;
  padding: 10px 10px !important;
  margin: 10px !important;
  font-size: 14px;
}

.searchList1-select select:focus {
  outline: none;
}

/*  search input style */

.searchList1 input {
  width: 25%;
  border: none;
  border: 1px solid #70a5d8;
  border-radius: 2px;
  height: 34px;
  color: #000 !important;
  padding: 0px 10px !important;
}

.searchList1 input:focus {
  outline: none !important;
}

/*  all table style  */

.balance-transaction table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 5px;
  text-align: center;
}

.balance-transaction table tr th {
  background-color: var(--secondary-color);
  color: #fff;
  padding: 8px 0px;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
}

.balance-transaction table td,
th {
  text-align: left;
  padding: 8px;
  text-align: center;
}

.balance-transaction table td {
  color: #252733;
  font-size: 12px;
}

.balance-transaction table tr:hover {
  background-color: #e0ecfb !important;
}
